/* eslint-disable */

export const useCalc = () => {
  const beginn = (e) => {
    return e?.startDate
  }

  const heim = (e) => {
    return e?.teams?.find(t => t.home)
  }

  const gast = (e) => {
    return e?.teams?.find(t => !t.home)
  }

  const mode = (e) => {
    return e?.mode
  }

  const halle = (e) => {
    if (e?.venue?.name) return `${e?.venue?.name}, ${e?.venue?.address?.addressLocality}`
    return e?.venue?.address?.addressLocality
  }

  const jahr = (e) => {
    return e?.parent?.parent?.name || ''
  }

  const liga = (e) => {
    return e?.parent?.name
  }

  const geraete = (e, df, all) => {
    return e?.disciplines?.map(d => ({
      ...d,
      name: df?.find(d2 => d2._id === d._discipline)?.name,
      shortName: df?.find(d2 => d2._id === d._discipline)?.shortName,
      regular: df?.find(d2 => d2._id === d._discipline)?.regular
    }))?.filter(d => all || d.regular)?.sort((a, b) => a.order < b.order ? -1 : 1) || []
  }

  const ds = (e, df) => {
    return (df?.map(d => ({
      ...d,
      __typename: undefined,
      active: !!e?.disciplines?.find(ed => ed._discipline === d._id),
      order: e?.disciplines?.find(ed => ed._discipline === d._id)?.order
    })) || [])
      .filter(d => d.active && d.regular)
      .sort((a, b) => {
        return a.order < b.order ? -1 : 1
      })
  }

  const geraet = (df, did) => {
    return df?.find(d => d._id === did) || {}
  }

  const geraetnr = (e, df, did) => {
    return geraete(e, df).findIndex(d => d._discipline === did)
  }

  const judge = (e, type) => {
    return e?.judges?.find(j => j.type === type && j.person !== null)
  }

  const trainer = (e, tid, nr) => {
    const tr = e?.teams?.find(t => t.team._id === tid)?.trainer
    if (!tr?.length) return null
    const tmp = tr?.find(t => t.order === nr)
    return tmp || tr[0]
  }

  const maxturner = (e) => {
    switch (mode(e)) {
      case 'wk_score4':
      case 'lp_3aus4':
      case 'lf_4aus4':
        return 4
      case 'wk_4aus5':
      case 'lf_4aus5':
        return 5
      case 'test':
        return 10
    }
    return 0
  }

  const wertungsturner = (e) => {
    switch (mode(e)) {
      case 'lp_3aus4':
        return 3
      case 'wk_score4':
      case 'lf_4aus4':
      case 'wk_4aus5':
      case 'lf_4aus5':
      case 'test':
        return 4
    }
    return 0
  }

  const mannschaftsgeraetefolge = (e, df, tid) => {
    const disc = e?.disciplines?.slice()?.sort((a, b) => a.order < b.order ? -1 : 1) || []
    const start = e?.teams?.find(t => t.team._id === tid)?._startdiscipline

    const index = disc.findIndex(d => d._discipline === start)

    if (index === -1) return disc
    const tmp = disc.splice(0, index)
    return [...disc, ...tmp]
  }

  const mannschaftenangeraet = (e, df, did) => {
    const t = e?.teams?.slice()
      ?.map(t => ({
        ...t,
        df: mannschaftsgeraetefolge(e, df, t.team._id).findIndex(d => d._discipline === did)
      }))
      ?.sort((a, b) => {
        if (a.df !== b.df) return a.df < b.df ? -1 : 1
        return a.order < b.order ? -1 : 1
      })
    if (!t || t.length === 0) return []
    if (!!e?.mode?.match(/^l[pf]_/)) return t
    const rotate = geraetnr(e, df, did) % t.length
    if (rotate === 0) return t
    const tmp = t.splice(0, rotate)
    t.push(...tmp)
    return t
  }

  const mannschaftsliste = (e, df, did) => {
    const teams = mannschaftenangeraet(e, df, did)
    if (mode(e) === 'wk_score4') {
      const ret = []
      ret.push(...teams)
      ret.push(...teams)
      ret.push(...teams.slice().reverse())
      ret.push(...teams.slice().reverse())
      return ret.map((t, i) => ({
        team: t.team,
        home: t.home,
        nr: Math.floor(i / 2) + 1
      }))
    }
  }

  const turnerangeraet = (e, r, df, did, tid) => {
    if (e.mode === 'wk_score4') {
      return mannschaftsliste(e, df, did).map(t => {
        const tmp = mannschaftturnerangeraet(e, r, t.team._id, did, t.nr)
        return {
          person: tmp?.person,
          result: tmp?.result,
          team: t.team,
          order: t.nr,
          home: t.home
        }
      })
    } else if (e.mode === 'wk_4aus5' || e.mode === 'test') {
      return [0, 1, 2, 3, 4].reduce((acc, curr) => {
        acc.push(...mannschaftenangeraet(e, df, did).map(t => {
          const tmp = mannschaftturnerangeraet(e, r, t.team._id, did)?.[curr]
          return tmp ? {
            person: tmp.person,
            result: tmp.result,
            team: tmp.team,
            order: tmp.order
          } : null
        }))
        return acc
      }, []).filter(a => !!a)
    } else if (e.mode === 'lp_3aus4' || e.mode === 'lf_4aus5' || e.mode === 'lf_4aus4') {
      if (tid) {
        const t = mannschaftturnerangeraet(e, r, tid, did)
        return [0, 1, 2, 3, 4].map(nr => t?.[nr]).filter(t => t)
      }
      else {
        return mannschaftenangeraet(e, df, did).reduce((acc, curr) => {
          const t = mannschaftturnerangeraet(e, r, curr.team._id, did)
          acc.push(...[0, 1, 2, 3, 4].map(nr => t?.[nr]).filter(t => t))
          return acc
        }, [])
      }
    }
  }

  const mannschaftturnerangeraet = (e, r, tid, did, nr) => {
    const turner = r?.filter(r => r._discipline === did && r._team === tid) || []
    const tmp = turner
      .map(t => ({
        person: t.person,
        team: t.team,
        order: t.order,
        result: t
      }))
      ?.sort((a, b) => a.order < b.order ? -1 : 1) || []
    return nr !== undefined ? tmp[nr - 1] : tmp
  }

  const mannschaftturnerangeraetfertig = (e, r, tid, did) => {
    return mannschaftturnerangeraet(e, r, tid, did)?.filter(t => t.result?.final !== undefined && t.result?.final !== null)
  }

  const mannschaftgeraetergebnis = (e, r, tid, did) => {
    const turner = r?.filter(r => r._discipline === did && r._team === tid) || []
    if (e?.mode === 'wk_score4') {
      return Math.round(turner.map(t => t?.score !== null ? t?.final || 0 : 0).sort((a, b) => a > b ? -1 : 1).reduce((acc, curr) => acc + curr, 0) * 100) / 100
    }
    return Math.round(turner.map(t => t?.final || 0).sort((a, b) => a > b ? -1 : 1).slice(0, wertungsturner(e)).reduce((acc, curr) => acc + curr, 0) * 100) / 100
  }

  const mannschaftgesamtergebnis = (e, r, tid) => {
    return Math.round(e.disciplines.reduce((acc, curr) => acc + mannschaftgeraetergebnis(e, r, tid, curr._discipline), 0) * 100) / 100
  }

  const mannschaftgeraetergebnisscore = (e, r, tid, did) => {
    const turner = mannschaftturnerangeraet(e, r, tid, did)
    return turner.map(t => t?.result?.score || 0).sort((a, b) => a > b ? -1 : 1).slice(0, 4).reduce((acc, curr) => acc + curr, 0)
  }

  const mannschaftgesamtergebnisscore = (e, r, tid) => {
    return e.disciplines.reduce((acc, curr) => acc + mannschaftgeraetergebnisscore(e, r, tid, curr._discipline), 0)
  }

  const mannschaftgeraetergebnisgp = (e, r, tid, did) => {
    const t = e.teams || []

    const re = t.map(t => ({
      _team: t.team._id,
      final: mannschaftgeraetergebnis(e, r, t.team._id, did),
      score: mannschaftgeraetergebnisscore(e, r, t.team._id, did)
    }))

    if (e.mode === 'wk_score4') {
      const own = re.find(r => r._team === tid)
      const other = re.find(r => r._team !== tid)

      if (own === undefined || other === undefined) return 0
      if (own.score === other.score) return 1
      return own.score > other.score ? 2 : 0
    } else if (e.mode === 'wk_4aus5' || e.mode === 'test') {
      const own = re.find(r => r._team === tid)
      const other = re.find(r => r._team !== tid)

      if (own === undefined || other === undefined) return 0
      if (Math.abs(own.final - other.final) < 0.01) return 1
      return own.final > other.final ? 2 : 0
    } else if (!!e.mode.match(/^l[pf]_/)) {
      const own = re.find(r => r._team === tid)
      const other = re.filter(r => r._team !== tid)

      if (own === undefined) return 0
      return other.reduce((acc, curr) => {
        if (Math.abs(own.final - curr.final) < 0.01) return acc + 0.5
        return acc + (own.final > curr.final ? 1 : 0)
      }, 0)
    }
  }

  const mannschaftgesamtergebnisgp = (e, r, df, tid) => {
    return geraete(e, df).reduce((acc, curr) => acc + mannschaftgeraetergebnisgp(e, r, tid, curr._discipline), 0)
  }

  const mannschaftgesamtergebnistp = (e, r, tid) => {
    const t = e.teams || []

    const re = t.map(t => ({
      _team: t.team._id,
      final: mannschaftgesamtergebnis(e, r, t.team._id),
      score: mannschaftgesamtergebnisscore(e, r, t.team._id)
    }))

    if (e.mode === 'wk_score4') {
      const own = re.find(r => r._team === tid)
      const other = re.find(r => r._team !== tid)

      if (!own || !other) return 0
      if (own.score === other.score) return 1
      return own.score > other.score ? 2 : 0
    } else if (e.mode === 'wk_4aus5' || e.mode === 'test') {
      const own = re.find(r => r._team === tid)
      const other = re.find(r => r._team !== tid)

      if (!own || !other) return 0
      if (Math.abs(own.final - other.final) < 0.01) return 1
      return own.final > other.final ? 2 : 0
    } else if (!!e.mode.match(/^l[pf]_/)) {
      const own = re.find(r => r._team === tid)
      const other = re.filter(r => r._team !== tid)

      if (own === undefined) return 0
      return other.reduce((acc, curr) => {
        if (Math.abs(own.final - curr.final) < 0.01) return acc + 0.5
        return acc + (own.final > curr.final ? 1 : 0)
      }, 0)
    }

    return 0
  }

  const aktivesgeraet = (e, r, df) => {
    return geraete(e, df).reduce((acc, curr) => {
      if (!acc) return curr._discipline

      if (e?.teams?.find(t => t.athletes?.find(a => r?.find(r => r._discipline === curr._discipline && (e.mode === 'wk_score4' || r.final !== null))))) return curr._discipline
      return acc
    }, null)
  }

  const getTabs = (es, rs, df) => {
    const tabs = []

    for (const id in es) {
      if (es[id].mode === 'wk_4aus5' || es[id].mode === 'test') {
        if (!es[id].teams?.find(t => !!t?.result?.final)) {
          tabs.push({ id, did: aktivesgeraet(es[id], rs[id], df), route: 'geraet/wk_4aus5', title: `${es[id].parent.name}: aktuelles Gerät`, time: 30000 })
          tabs.push({ id, route: 'uebersicht/wk_4aus5', title: `${es[id].parent.name}: Übersicht`, time: 10000 })
          tabs.push(...geraete(es[id], df).map(d => ({
            id,
            did: d._discipline,
            route: 'geraet/wk_4aus5',
            title: `${es[id].parent.name}: ${d.name}`,
            hidden: true
          })))
          // tabs.push({ id, route: 'mannschaft/wk_4aus5', title: `${es[id].parent.name}: Mannschaften`, hidden: true })
        } else {
          tabs.push({ id, route: 'finished/wk_4aus5', title: `${es[id].parent.name}: Ergebnis` })
        }
      } else if (es[id].mode === 'wk_score4') {
        if (!es[id].teams?.find(t => !!t?.result?.final)) {
          tabs.push({ id, did: aktivesgeraet(es[id], rs[id], df), route: 'geraet/wk_score4', title: `${es[id].parent.name}: aktuelles Gerät`, time: 30000 })
          tabs.push({ id, route: 'uebersicht/wk_score4', title: `${es[id].parent.name}: Übersicht`, time: 10000 })
          tabs.push(...geraete(es[id], df).map(d => ({
            id,
            did: d._discipline,
            route: 'geraet/wk_score4',
            title: `${es[id].parent.name}: ${d.name}`,
            hidden: true
          })))
          // tabs.push({ id, route: 'mannschaft/wk_score4', title: `${es[id].parent.name}: Mannschaften`, hidden: true })
        } else {
          tabs.push({ id, route: 'finished/wk_score4', title: `${es[id].parent.name}: Ergebnis` })
        }
      } else if (!!es[id].mode.match(/^l[fp]_/)) {
        if (es[id].status !== 'EventFinished' && es[id].status !== 'EventConfirmed') {
          tabs.push({ route: 'lf/startgeraet', title: `Startgeraet`, hidden: es[id].status === 'EventStarted' })
          tabs.push({ route: 'lf/turner', title: `Letzte Turner`, hidden: es[id].status !== 'EventStarted', time: 30000 })
          tabs.push({ id, route: 'uebersicht/lf', title: `${es[id].parent.name}: Übersicht`, hidden: es[id].status !== 'EventStarted', time: 10000 })
          tabs.push(...geraete(es[id], df).map(d => ({
            did: d._discipline,
            route: 'geraet/lf',
            title: d.name,
            hidden: true
          })))
          tabs.push({ id, route: 'finished/lf', title: `${es[id].parent.name}: Mannschaften`, hidden: true })
        } else {
          tabs.push({id, route: 'finished/lf', title: `${es[id].parent.name}: Ergebnis`})
        }
      }
    }

    return tabs
  }

  const kari = (e, did, type) => {
    const tmp = e?.judges?.filter(j => j.type === type && j.person !== null)
    const d = tmp?.find(j => j._discipline === did)
    if (d) return d
    return tmp?.find(j => !j._discipline)
  }

  const calcscore = (a, b) => {
    if (!a) a = 0
    if (!b) b = 0

    const diff = Math.round((a - b) * 100) / 100

    if (diff < 0.14) return 0
    if (diff <= 0.26) return 1
    if (diff <= 0.51) return 2
    if (diff <= 1.01) return 3
    if (diff <= 2.01) return 4
    if (diff <= 6.01) return 5
    return 10
  }

  return {
    ds,
    beginn, mode, heim, gast, getTabs, geraet, halle, jahr, liga, judge, trainer,
    geraete,
    aktivesgeraet,
    turnerangeraet,
    mannschaftturnerangeraet, mannschaftturnerangeraetfertig, mannschaftgeraetergebnis, mannschaftgesamtergebnis,
    mannschaftgeraetergebnisscore, mannschaftgesamtergebnisscore,
    mannschaftgeraetergebnisgp, mannschaftgesamtergebnisgp,
    mannschaftgesamtergebnistp,
    mannschaftenangeraet, mannschaftsliste,
    maxturner,
    kari,
    calcscore
  }
}
