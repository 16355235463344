<template>
  <v-container fluid>
    <v-row>
      <v-col
        xl="2"
        lg="3"
        md="4"
        cols="12"
        class="ma-0 pa-0">
        <v-virtual-scroll
          :height="height"
          :items="items"
          item-height="58"
          :bench="3"
          class="grey lighten-4"
        >
          <template v-if="!isScore" #default="{item}">
            <v-list-item @click="open(item)" :class="{red: result.data && item.result._id === result.id, 'lighten-3': true}">
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.person | person }}<br>
                  <span style="font-size:70%;font-style:italic;color:rgb(128,128,128);">({{ item.team.name }})</span>
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>{{ item.final | float2 }}</v-list-item-action>
            </v-list-item>
            <v-divider />
          </template>
          <template v-else #default="{item}">
            <v-list-item v-if="item.person" @click="open(item)" :class="{red: result.data && item.result._id === result.id, 'lighten-3': true}">
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.person | person }}<br>
                  <span style="font-size:70%;font-style:italic;color:rgb(128,128,128);">({{ item.team.name }})</span>
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action v-if="item.final !== null">{{ item.final | float2 }} ({{ item.score }})</v-list-item-action>
              <v-list-item-action v-else @click.stop="delturner(item)"><v-icon>far fa-trash-alt</v-icon></v-list-item-action>
            </v-list-item>
            <v-list-item v-else @click="openturner(item)" class="lighten-3">
              <v-list-item-content>
                <v-list-item-title>
                  Turner hinzufügen<br>
                  <span style="font-size:70%;font-style:italic;color:rgb(128,128,128);">({{ item.team.name }})</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider />
          </template>
        </v-virtual-scroll>
      </v-col>
      <v-col
        v-if="inline && result.open"
        xl="10"
        lg="9"
        md="8"
      >
        <ergebnis :id="result.id" :did="did" :e="e" :df="df" @del="del" @save="save" @close="close" />
      </v-col>
      <base-material-dialog
        v-else
        v-model="result.open"
        icon="far fa-poll-h"
        :title="`Wertung eingeben: ${dname}`"
        :sub-title="`${pname} (${tname})`"
        color="primary"
        @esc="close"
      >
        <ergebnis :id="result.id" :did="did" :e="e" :df="df" @del="del" @save="save" @close="close" />
      </base-material-dialog>
      <base-material-dialog
        v-model="turneradd.open"
        icon="far fa-user-plus"
        :title="`Turner hinzufügen: ${turneradd.discipline}`"
        :sub-title="turneradd.team"
        color="primary"
        :width="400"
        @esc="closeturner"
        :actions="['close']"
        @close="closeturner"
      >
        <v-list>
          <v-list-item
            v-for="a in turneradd.athletes"
            :key="a.person._person"
            @click="addturner(a)"
          >
            {{ a.order }}: {{ a.person | person }}
          </v-list-item>
        </v-list>
      </base-material-dialog>
    </v-row>
  </v-container>
</template>

<script>
import { useGraphQL } from '@/plugins/graphql'
import { useCalc } from '../../../plugins/calc'
import gql from 'graphql-tag'

export default {
  name: 'geraet',

  components: {
    Ergebnis: () => import('./ergebnis')
  },

  setup (props, context) {
    return {
      ...useGraphQL(context),
      ...useCalc()
    }
  },

  props: {
    id: {
      type: String,
      required: true
    },
    did: {
      type: String,
      required: true
    },
    e: {
      type: Object,
      required: true
    },
    r: {
      type: Array,
      required: true
    },
    df: {
      type: Array,
      required: true
    },
    f: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    result: {
      open: false,
      id: null,
      data: null
    },
    turneradd: {
      open: false,
      team: null,
      discipline: null,
      order: -1,
      tid: null,
      athletes: []
    },
    windowHeight: window.innerHeight,
    documentHeight: -1,
    resizeObserver: null
  }),

  computed: {
    d () {
      return this.df?.find(d => d._id === this.did) || {}
    },
    height () {
      if (this.f) return this.windowHeight - 260
      const tmp = Math.max(this.windowHeight, this.documentHeight)
      return tmp < 900 ? 500 : tmp - 400
    },
    items () {
      return this.turnerangeraet(this.e, this.r, this.df, this.did).map(t => ({
        type: 'turner',
        person: t.person,
        team: t.team,
        result: t.result,
        final: t.result?.final,
        score: t.result?.score,
        pen: t.result?.penalty,
        order: t.order
      }))
    },
    inline () {
      return !!['lg', 'xl'].find(b => b === this.$vuetify.breakpoint.name)
    },
    dname () {
      return this.geraet(this.df, this.result.data?.result?._discipline)?.name
    },
    pname () {
      return this.$options.filters.person(this.result.data?.person)
    },
    tname () {
      return this.result.data?.team?.name
    },
    ori () {
      return this.r?.find(r => r._id === this.result.id) || null
    },
    isScore () {
      return this.e.mode === 'wk_score4'
    }
  },

  methods: {
    open (result) {
      this.result.data = result
      this.result.id = result?.result?._id
      this.result.open = !!result
    },
    onResize () {
      this.windowHeight = window.innerHeight
      this.documentHeight = Math.max(document.body.scrollHeight, document.body.offsetHeight, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight) - 50
    },
    close () {
      this.open(null)
    },
    openturner (item) {
      const team = this.e.teams.find(t => t.team._id === item.team._id)
      const athletes = team?.athletes || []
      const results = this.r.filter(r => r._team === item.team._id && r._discipline === this.did)
      this.turneradd.athletes = athletes.filter(a => !results.find(r => r._person === a.person._id)).sort((a, b) => a.order < b.order ? -1 : 1)
      this.turneradd.team = team.team.name
      this.turneradd.discipline = this.geraet(this.df, this.did)?.name
      this.turneradd.order = item.order
      this.turneradd.tid = item.team._id
      this.turneradd.open = true
    },
    closeturner () {
      this.turneradd.open = false
      this.turneradd.team = null
      this.turneradd.discipline = null
      this.turneradd.athletes = []
      this.turneradd.order = -1
      this.turneradd.tid = null
    },
    async addturner (p) {
      await this.mutate({
        mutation: gql`
          mutation($event: UUID!, $team: UUID!, $person: UUID!, $discipline: UUID!, $order: Int!) {
            StbM2021WkTeamAthleteResultAdd(event: $event, team: $team, person: $person, discipline: $discipline, order: $order) {
              _id
            }
          }
        `,
        variables: {
          event: this.id,
          team: this.turneradd.tid,
          person: p.person._id,
          discipline: this.did,
          order: this.turneradd.order
        }
      })

      this.closeturner()
    },
    async delturner (item) {
      if (item.result._id === this.result.id) this.close()

      await this.mutate({
        mutation: gql`
          mutation($event: UUID!, $team: UUID!, $person: UUID!, $discipline: UUID!) {
            StbM2021WkTeamAthleteResultDelete(event: $event, team: $team, person: $person, discipline: $discipline)
          }
        `,
        variables: {
          event: this.id,
          team: item.team._id,
          person: item.person._id,
          discipline: this.did
        }
      })
    },
    async save (data, nonext) {
      const ori = data._id ? this.r.find(r => r._id === data._id) : this.ori

      const cmp = 'ne,a,b,c,d,e,f,g,h,i,parts,eg,e1,e2,e3,e4,ok,_sprung,bonus,dscore,deduction,escore,penalty,final,score'.split(',')

      const neu = {}

      for (const i of cmp) {
        if (ori[i] !== data[i]) neu[i] = data[i]
      }

      if (Object.keys(neu).length === 0) return nonext ? null : this.next()

      neu.id = data._id

      await this.mutate({
        mutation: gql`mutation($id: UUID!, $ne: Int, $a: Int, $b: Int, $c: Int, $d: Int, $e: Int, $f: Int, $g: Int, $h: Int, $i: Int, $parts: Int, $eg: Float, $e1: Float, $e2: Float, $e3: Float, $e4: Float, $ok: Float, $_sprung: Int, $bonus: Boolean, $deduction: Float, $dscore: Float, $escore: Float, $penalty: Float, $final: Float, $score: Int) {
            StbM2021WkTeamAthleteResultUpdate(id: $id, ne: $ne, a: $a, b: $b, c: $c, d: $d, e: $e, f: $f, g: $g, h: $h, i: $i, parts: $parts, eg: $eg, e1: $e1, e2: $e2, e3: $e3, e4: $e4, ok: $ok, sprung: $_sprung, bonus: $bonus, dscore: $dscore, deduction: $deduction, escore: $escore, penalty: $penalty, final: $final, score: $score) { _id }
          }`,
        variables: neu
      })

      if (!nonext) this.next()
    },
    async del (scoregegner) {
      if (await this.$root.$children[0].$refs.confirm.open('Wertung löschen?', 'Die Wertung kann nicht wiederhergestellt werden!')) {
        const cmp = 'ne,a,b,c,d,e,f,g,h,i,parts,eg,e1,e2,e3,e4,ok,sprung,bonus,dscore,deduction,escore,penalty,final,score'.split(',')
        const neu = { _id: this.result.id }
        for (const i of cmp) {
          neu[i] = null
        }

        this.save(neu, true)
        if (scoregegner) {
          console.log({ _id: scoregegner, score: null })
          this.save({ _id: scoregegner, score: null }, true)
        }
      }
    },
    next () {
      if (!this.inline) return this.close()

      const a = this.turnerangeraet(this.e, this.r, this.df, this.did)
      const i = a.findIndex(t => t.result._id === this.result.id)
      if (!a?.[i + 1]?.result) {
        this.close()
      } else {
        this.open(a[i + 1])
      }
    }
  },

  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
      this.resizeObserver = new ResizeObserver(this.onResize)
      this.resizeObserver.observe(document.body)
      this.onResize()
    })
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
    this.resizeObserver.disconnect()
  }
}
</script>

<style scoped>

</style>
